import axios from 'axios';

const api = axios.create({
  //baseURL: 'https://localhost:7284/api/', // local api
  baseURL:'https://dtswebapi-afh9aqanevhta5e6.australiasoutheast-01.azurewebsites.net/api/',
  headers: {
    'Content-Type': 'application/json',
  },
});

export default api;