import React from 'react'
import rightArrow from '../assets/images/right-arrow-icon.svg'

function ArrowButton(props) {
    return (
        <div className='arrow-btn-section'>
            <div className='arrow-btn'>
                {props.btnName}
            </div>
            <div className='right-arrow'>
                <img src={rightArrow} />
            </div>
        </div>
    )
}

export default ArrowButton