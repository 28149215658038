import React from 'react';
import logo from '../assets/images/DTS_LOGO.png'
import { Outlet, Link } from "react-router-dom";


const Footer = () => {
  return (
    <>      
      <section className="footer">
        <div className="footer-wrap">
          <div className="f-grid">
            <div className="footer-links">
              <div className="subtitle-footer">Navigate</div>
              <Link to="/">Home</Link>
              <Link to="/about">About</Link>
              <Link to="/service">Service</Link>
              <Link to="/contact">Contact Us</Link>
            </div>
            <div className="footer-links">
              <div className="subtitle-footer">Services</div>
              <Link to="/service">Values at DTS</Link>
              <Link to="/service">Proven Experties</Link>
              <Link to="/service">Roots of Reliability</Link>
              <Link to="/service">Where saftey comes first</Link>
            </div>
            <div className="footer-links">
              <div className="subtitle-footer">Contact Info</div>
              <Link to="/contact">Enquiries@dynamictrafficsolutions.com.au</Link>
              <Link to="/contact">0430 134 822</Link>
            </div>
            {/* <div className="footer-links">
              <div className="subtitle-footer">Socials</div>
              <a href="#" className="footer-link">Some Text</a>
              <a href="#" className="footer-link">Some Text</a>
              <a href="#" className="footer-link">Some Text</a>
            </div> */}
            <div className="footer-logo-wrap">
            <Link to="/"><img src={logo} /></Link>
            </div>
            <div className="site-details">
           <div>© Dynamic Traffic Solutions 2024.<br />All Rights Reserved</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
