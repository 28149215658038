import React from 'react';

const TitleDescriptionRows = ({ data }) => {
  return (
    <div className='rows-container'>
      {data.map((item, index) => (
        <>        
        <div className='row' key={index}>
          <div className='row-title' style={{fontSize: '30px'}}>
            <h2 ><b>{item.title}</b></h2>
          </div>
          <div className='row-description' >
            <p style={{fontSize: '24px'}}>{item.description}</p>
          </div>
        </div>
        <div className='btm-line'></div>
        </>
      ))}
    </div>
  );
};

export default TitleDescriptionRows;
