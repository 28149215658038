import React from 'react';

const ContactInfo = ({ iconSrc, title, description }) => {
    return (
        <div className="contact-info">
            <div className="icon-box-wrapper">
                <div className="icon-box-icon">
                    <img src={iconSrc} alt={title} className="icon" />
                </div>
                <div className="icon-box-content">
                    <h4 className="icon-box-title">
                        <span>{title}</span>
                    </h4>
                    <p className="icon-box-description">
                        {description}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ContactInfo;
