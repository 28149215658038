import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import contactHeaderImg from '../assets/images/contact-img1.jpg';
import InputTextBox from '../components/InputTextBox';
import TextAreaInput from '../components/TextAreaInput';
import SubmitButton from '../components/SubmitButton';
import ContactInfo from '../components/ContactInfo';
import contactBtmImg from '../assets/images/contact-img2.png'
import rightTickIcon from '../assets/images/right-tick.svg'
//API call
import api from '../pages/API';
// Import local icons
import phoneIcon from '../assets/images/call_icon.svg';
import emailIcon from '../assets/images/envelope_lines.svg';
import locationIcon from '../assets/images/location_icon.svg';
import Loader from '../components/Loader';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Contact() {
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);
    const contactDetails = [
        { iconSrc: phoneIcon, title: 'Phone Number', description: '0430 134 822' },
        { iconSrc: emailIcon, title: 'Email', description: 'Enquiries@dynamictrafficsolutions.com.au' },
        { iconSrc: locationIcon, title: 'Location', description: 'Adelaide, Australia' },
    ];

    const [users, setUsers] = useState([]);
    const [formData, setFormData] = useState({ firstName: '', lastName: '', email: '', body: '' });

    const [feedback, setFeedback] = useState([]);
    const [formDataFeedback, setFormDataFeedback] = useState({ name: '', mobileNumber:'' , email: '', body: '' });
    const [error, setError] = useState(null);
    
    var [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);
    var [isSubmittingInquiry, setIsSubmittingInquiry] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);

        setIsSubmittingInquiry(true);

        // Send Mail
        api.post('/sendmail/send', formData)
            .then((response) => {
                
                setIsSubmittingInquiry(false);
                if (response) {
                    toast.success("Your Message submitted!");
                    setUsers([...users, response.data]);
                    setFormData({ firstName: '', lastName: '', email: '', body: '' }); // Reset form
                } else {
                    toast.error("Something want to wrong! Please try  agin.");
                }
            })
            .catch((error) => {
                // Handle validation errors or other errors from the server
                if (error.response && error.response.data && error.response.data.errors) {
                    Object.keys(error.response.data.errors).forEach(element => { 
                        error.response.data.errors[element].forEach(ele => {

                            toast.error(ele);
                        });                       
                    });
                } else {
                    toast.error("Something want to wrong! Please try  agin.");
                }});

    };

    const handleFeedbackSubmit = (e) => {
        e.preventDefault();
        // Send Mail
        
        setIsSubmittingFeedback(true);

        api.post('/feedback/send', formDataFeedback)
            .then((response) => {
                
                setIsSubmittingFeedback(false);
                if (response) {
                    toast.success("Your feedback is submitted!");
                    setFeedback([...feedback, response.data]);
                    setFormDataFeedback({ name: '', mobileNumber: '', email: '', body: '' }); // Reset form
                } else {
                    toast.error("Something want to wrong! Please try  agin.");
                }
            })
            .catch((error) => {
                // Handle validation errors or other errors from the server
                if (error.response && error.response.data && error.response.data.errors) {
                    Object.keys(error.response.data.errors).forEach(element => { 
                        error.response.data.errors[element].forEach(ele => {

                            toast.error(ele);
                        });                       
                    });
                } else {
                    toast.error("Something want to wrong! Please try  agin.");
                }});

    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleInputChangeFeedback = (e) => {
        const { name, value } = e.target;
        setFormDataFeedback({
            ...formDataFeedback,
            [name]: value,
        });
    };

    return (
        <div className='contact-page'>
            <Loader />
            <Header />
            <div>
                <section>
                    <div className='contact-header'>
                        <h2 style={{fontSize: '54px'}}>Contact Us</h2>
                    </div>
                </section>
                <section className='contact-info-section' >
                    <div className='contact-left'>
                        <div className='contact-content'>
                            {/* <div className='contact-title'><b style={{fontSize: '24px'}}>CONTACT US</b></div> */}
                            <div>
                               <b> <h1 className='contact-subtitle'>Get In <span><span className='primary-color'>Touch</span></span></h1></b>
                            </div><br></br>
                        </div>

                        <div className="contact-grid">
                            {contactDetails.map((contact, index) => (
                                <ContactInfo
                                    key={index}
                                    iconSrc={contact.iconSrc}
                                    title={contact.title}
                                    description={contact.description}
                                />
                            ))}
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>

                        <div className='contact-right'>
                            <div className='contact-fName-lName'>
                                <div className='fName-input'>
                                    <InputTextBox placeholder="Enter your first name" maxLength={50} name="firstName"
                                        value={formData.firstName} OnChangeText={handleInputChange}  />
                                </div>
                                <div className='lname-input'>
                                    <InputTextBox placeholder="Enter your last name" maxLength={50} name="lastName"
                                        value={formData.lastName}
                                        OnChangeText={handleInputChange} required />
                                </div>
                            </div>
                            <div className='mail-input'>
                                <InputTextBox type="email" placeholder="Enter your email" maxLength={50} name="email"
                                    value={formData.email}
                                    OnChangeText={handleInputChange} required />
                            </div>
                            <div className='msg-input'>
                                <TextAreaInput placeholder="Enter your inquiries" maxLength={5000} name="body" value={formData.body}
                                    OnChangeTextArea={handleInputChange}
                                    required />
                            </div>
                            <div>
                                <SubmitButton value="Submit" isSubmitting={isSubmittingInquiry} waitMessage="Please wait..." />
                            </div>
                        </div>
                    </form>
                    <ToastContainer />
                </section>
                <section>
                <form onSubmit={handleFeedbackSubmit}>
                
                    <div className='contact-btm-section'>
                        <div className='contact-btm-left'>
                            <img src={contactBtmImg} />
                           
                        </div>
                        <div className='contact-btm-right'>
                        <h1 className='contact-subtitle'>Feedback Form</h1><br></br>
                        <div className='feedback-form'>
                       
                        <div className='mail-input'>
                        
                                <InputTextBox 
                                placeholder="Enter your name" 
                                maxLength={50} name="name" value={formDataFeedback.name}
                                OnChangeText={handleInputChangeFeedback} required />
                            </div>
                            <div className='contact-fName-lName'>
                                <div className='fName-input'>
                                    <InputTextBox 
                                    placeholder="Enter your mobile number" 
                                    maxLength={10} type="number" name="mobileNumber" value={formDataFeedback.mobileNumber}
                                    OnChangeText={handleInputChangeFeedback} required />
                                </div>
                                <div className='lname-input'>
                                    <InputTextBox type="email" 
                                    placeholder="Enter your email" 
                                    maxLength={50} name="email" value={formDataFeedback.email}
                                    OnChangeText={handleInputChangeFeedback} required />
                                </div>
                            </div>
                            
                            <div className='msg-input'>
                                <TextAreaInput placeholder="Feedback message"
                                maxLength={250} name="body" value={formDataFeedback.body}
                                 OnChangeTextArea={handleInputChangeFeedback}
                                    required />
                            </div>
                        </div>
                            <div>
                                <SubmitButton value="Submit" isSubmitting={isSubmittingFeedback} waitMessage="Please wait..." />
                            </div>
                        </div>

                        {/* <div className='contact-btm-right'>
                            <h1>Lorem ipsum dolor sit amet, <span className='contact-subtitle'>Lorem ipsum</span></h1>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam iaculis vulputate dui at fringilla. Pellentesque volutpat ipsum dolor, vitae venenatis dolor posuere quis.
                            </p>
                            <div className='contact-h-txt'>
                                <ul>
                                    <li>
                                        <img src={rightTickIcon} />
                                        <span>Lorem ipsum</span>
                                    </li>
                                    <li>
                                        <img src={rightTickIcon} />
                                        <span>Lorem ipsum</span>
                                    </li>
                                    <li>
                                        <img src={rightTickIcon} />
                                        <span>Lorem ipsum</span>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <SubmitButton value="Some Text" waitMessage="Please wait..." />
                            </div>
                        </div> */}


                    </div>
                </form>
                </section>
            </div>
            <Footer />
        </div>
    );
}

export default Contact;
