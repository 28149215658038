import React , { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import aboutImg1 from '../assets/images/img4.jpg'
import aboutImg2 from '../assets/images/img3.jpg'
import aboutImg3 from '../assets/images/img5.jpg'
import Loader from '../components/Loader'
import TitleDescriptionRows from '../components/TitleDescriptionRows'
import '../assets/css/About.css'

function About() {
  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);
  }, []);
  const rowsData = [
    {
      title: "Commitment to Excellence",
      description: "At Dynamic Traffic Solutions, we set the highest standards for every project we undertake. Whether ensuring pedestrian safety at events or managing complex roadwork traffic control, our commitment to excellence remains unwavering. From the planning phase to execution, our team consistently delivers exceptional results, always striving to exceed client expectations."
    },
    {
      title: "Empowerment and Inclusivity",
      description: "As a female-owned business, Dynamic Traffic Solutions fosters a work environment that embraces empowerment and inclusivity. We encourage every team member to grow, excel, and contribute their unique perspectives. This strengthens our internal culture and enables us to offer diverse, creative solutions to the challenges our clients face, ensuring the best possible outcomes for each project."
    },
    {
      title: "Community Focus",
      description: "Dynamic Traffic Solutions is a family-owned business, deeply connected to the community. We take pride in our role in enhancing public safety and improving local infrastructure. By collaborating closely with councils, businesses, and event organisers, we bring a personal touch to every project that larger corporations may overlook. Our commitment to supporting community projects reflects our dedication to creating safer, more efficient spaces for everyone"
    }
  ];
  return (
    <div className='about-page'>
      <Loader />
      <Header />
      <div className='about-page'>
      <div classsName='service-h2' style={{ textAlign: 'center' }}><br></br><br></br>
          <h1>About Us</h1><br></br>
        </div>
        <TitleDescriptionRows data={rowsData} />
        <section className='about-content-img'>
          <div className='about-left'>
            <h1>Proven Expertise: Our Experience & Qualifications</h1>
            <p style={{fontSize: '24px'}}>Experience in the Civil Industry: With over six years of hands-on experience in the civil industry, Dynamic Traffic Solutions has built a reputation for reliability and expertise. Our team has successfully managed a wide range of projects, from small-scale local events to large construction initiatives, always</p>
          </div>
          <div className='about-right about-header-img'>
            <img src={aboutImg1} />
          </div>
        </section>
        <section className='about-content-img'>
          <div className='about-right about-header-img pdng-rt30'>
            <img src={aboutImg2} />
          </div>
          <div className='about-left'>
            <h1>Roots of Reliability: A Family-Driven Business</h1>
            <p style={{fontSize: '24px'}}>Dynamic Traffic Solutions is more than just a business; it's a family-owned and female-owned enterprise with a passion for safety and service. Our family values drive our commitment to quality, ensuring that every project is handled with care, dedication, and a personal touch that larger corporations often overlook. As a female-owned business, we are proud to contribute to the diversity and strength of the civil industry.</p>
          </div>

        </section>
        <section className='about-content-img'>

          <div className='about-left'>
            <h1>Where Safety Comes First
            </h1>
            <p style={{fontSize: '24px'}}>Safety isn't just a priority; it's the foundation of everything we do at Dynamic Traffic Solutions. From our rigorous training programs to our on-site safety protocols, we are dedicated to creating safe environments for both our team and the communities we serve. Our commitment to safety is reflected in every aspect of our work, ensuring that all traffic management operations are conducted with the highest standards of precaution and care.</p>
          </div>
          <div className='about-right about-header-img'>
            <img src={aboutImg3} />
          </div>
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default About