import React , { useEffect } from 'react'
import serviceImg from '../assets/images/img6.jpg';
import serviceImg1 from '../assets/images/service1.png'
import serviceImg2 from '../assets/images/service2.png'
import serviceImg3 from '../assets/images/service3.png'
import serviceImg4 from '../assets/images/service4.png'
import Footer from '../components/Footer';
import Header from '../components/Header';
// import FAQAccordion from '../components/FAQAccordion';
import Loader from '../components/Loader';
import GridView from '../components/GridView'

function Service() {

  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  const gridData = [
    { label: "Builders" },
    { label: "Councils" },
    { label: "Government Departments" },
    { label: "Public Utilities" },
    { label: "Event Management" },
    { label: "Road Maintenance" },
    { label: "Infrastructure" },
    { label: "Services Provided 24/7" }
  ];

  return (
    <div className='service-page'>
      <Loader />
    <Header />
      <section className='service-first-section'>
        <div className='service-h1'>
          <h1>Services to all contractors</h1>
        </div>
        <div className='service-img'>
          <img className='maxW100' src={serviceImg} />
        </div>
        <div className='grid-view-section'>
        <GridView data={gridData} />
        </div>
      </section>
      <section className='service-second-section'>
        <div className='service-left service-right100'>
          <h2>Traffic Management for Government and Council: Streamlining Public Projects</h2>
          <p style={{fontSize: '24px'}}>We understand the critical role that government and council projects play in our communities. At Dynamic Traffic Solutions, we specialise in providing tailored traffic management solutions that ensure the smooth execution of public infrastructure projects. Our expertise lies in minimising public disruption, adhering to strict regulatory standards, and ensuring that all necessary approvals are obtained promptly. By collaborating closely with councils and government bodies, we help deliver projects that enhance public spaces safely and efficiently.
          </p>
        </div>
        <div className='service-right'>
          <img className='maxW100' src={serviceImg1} />
        </div>
      </section>
      <section className='service-second-section'>
      <div className='service-right'>
          <img className='maxW100' src={serviceImg2} />
        </div>
        <div className='service-left service-left100'>
          <h2>Traffic Management for Construction: Building Safely, Building Better</h2>
          <p style={{fontSize: '24px'}}>Construction sites present unique challenges, and effective traffic management is crucial to ensuring safety and efficiency. Our experienced team at Dynamic Traffic Solutions handles every aspect of roadwork traffic control, including lane closures, detours, and temporary signage. By prioritising the safety of both workers and the public, and employing cutting-edge technology, we adapt to the dynamic needs of construction sites, reducing risks and keeping traffic flowing smoothly around your projects.
          </p>
        </div>

      </section>
      <section className='service-second-section'>
        <div className='service-left service-right100'>
          <h2>Traffic Management for Events: Making Every Event a Success
          </h2>
          <p style={{fontSize: '24px'}}>Successful events require meticulous planning, and traffic management is a key component of that success. Dynamic Traffic Solutions provides comprehensive event traffic management services, tailored to the specific needs of your event. From large-scale festivals to community gatherings, we ensure that attendees can arrive and depart safely, and that potential issues like congestion and parking overflow are managed with expertise. Our team collaborates with event organisers to create a seamless experience for all guests.</p>
        </div>
        <div className='service-right'>
          <img className='maxW100' src={serviceImg3} />
        </div>
      </section>
      <section className='service-second-section'>
      <div className='service-right'>
          <img className='maxW100' src={serviceImg4} />
        </div>
        <div className='service-left service-left100'>
          <h2>Pedestrian Control: Ensuring Safe Passage for All
          </h2>
          <p style={{fontSize: '24px'}}>Pedestrian safety is a top priority in any traffic management plan. Dynamic Traffic Solutions excels in designing and implementing pedestrian management strategies that account for the unique characteristics of each location. Whether it’s establishing designated walkways, installing clear signage, or erecting secure safety barriers, we ensure that all pedestrian routes are compliant with council requirements and provide safe passage for everyone.</p>
        </div>
      </section>
      {/* <section className='service-testimonial'>
          <div>"</div>
          <div className='service-testimonial-txt'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,</div>
          <div className='service-testimonial-name'>Lorem Ipsum</div>
      </section>
      <section className='service-qa-section'>
        <FAQAccordion />
      </section> */}
      <Footer />
    </div>
  )
}

export default Service