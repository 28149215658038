import React, { useState } from 'react';
import peopleImg from '../assets/images/contact-img2.png'
import Footer from '../components/Footer';
import Header from '../components/Header';

const People = () => {
  const [filter, setFilter] = useState('all');

  // List of images and their categories
  const images = [
    { src: peopleImg, alt: "Mountains", category: "nature", title: "Mountains", subTitle: "Lorem Ipsum.....", dessription: "lorem ipsum lorem........." },
    { src: peopleImg, alt: "Lights", category: "nature", title: "Lights", subTitle: "Lorem Ipsum.....", dessription: "lorem ipsum lorem........." },
    { src: peopleImg, alt: "Nature", category: "nature", title: "Forest", subTitle: "Lorem Ipsum.....", dessription: "lorem ipsum lorem........." },
    { src: peopleImg, alt: "Car", category: "cars", title: "Retro", subTitle: "Lorem Ipsum.....", dessription: "lorem ipsum lorem........." },
    { src: peopleImg, alt: "Car", category: "cars", title: "Fast", subTitle: "Lorem Ipsum.....", dessription: "lorem ipsum lorem........." },
    { src: peopleImg, alt: "Car", category: "cars", title: "Classic", subTitle: "Lorem Ipsum.....", dessription: "lorem ipsum lorem........." },
    { src: peopleImg, alt: "Girl", category: "people", title: "Girl", subTitle: "Lorem Ipsum.....", dessription: "lorem ipsum lorem........." },
    { src: peopleImg, alt: "Man", category: "people", title: "Man", subTitle: "Lorem Ipsum.....", dessription: "lorem ipsum lorem........." },
    { src: peopleImg, alt: "Woman", category: "people", title: "Woman", subTitle: "Lorem Ipsum.....", dessription: "lorem ipsum lorem........." },
  ];

  // Function to handle filter button click
  const filterSelection = (category) => {
    setFilter(category);
  };

  // Determine if an image should be shown based on the selected filter
  const isImageVisible = (category) => {
    if (filter === 'all') return true;
    return filter === category;
  };

  return (

    <div className="people-page">
      <Header />
      <div className='container'>
        <h2 className='people-page-title' >Our People</h2>
        <div id="myBtnContainer">
          <button className={`btn ${filter === 'all' ? 'active' : ''}`} onClick={() => filterSelection('all')}>Show all</button>
          <button className={`btn ${filter === 'nature' ? 'active' : ''}`} onClick={() => filterSelection('nature')}>People 1</button>
          <button className={`btn ${filter === 'cars' ? 'active' : ''}`} onClick={() => filterSelection('cars')}>People 2</button>
          <button className={`btn ${filter === 'people' ? 'active' : ''}`} onClick={() => filterSelection('people')}>People 3</button>
        </div>
        <div className="people-row">
          {images.map((image, index) => (
            <div
              key={index}
              className={`people-column ${image.category} ${isImageVisible(image.category) ? 'show' : ''
                }`}
            >
              <div className="content">
                <img
                  className="image"
                  src={image.src}
                  alt={image.alt}
                  style={{ width: '100%' }}
                />
                <div className="people-txt">
                  <h4>{image.title}</h4>
                  <h5>{image.subTitle}</h5>
                </div>

                {/* Overlay container */}
                <div className="overlay">
                  <h4>{image.title}</h4>
                  <h5>{image.subTitle}</h5>
                  <p>{image.dessription}</p>
                  <a href="#read-more" className="read-more">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default People;
