// import React, { useState, useEffect } from 'react';
// import loaderImg from '../assets/images/DTS_LOGO.png';
// import loaderImgbanner from '../assets/images/banner.jpg';
// import AnimatedLogo from './AnimatedLogo';

// const Loader = () => {
//   const [loading, setLoading] = useState(true);
//   const [animate, setAnimate] = useState(true);

//   useEffect(() => {
//     // Start the down-to-up animation after 1 second
//     setTimeout(() => {
//       setAnimate(false); // Trigger the down-to-up animation
//     }, 2000); // Adjust the time as needed

//     // Stop the loader after the animation completes
//     setTimeout(() => {
//       setLoading(false); // Remove the loader and show the content
//     }, 5000); // Ensure this delay matches the total animation time
//   }, []);

//   return (
//     <>
//     {loading && (
//       <div className={`loader-container ${!animate ? 'down-to-up' : 'up-to-down'}`}>
//         <div className="loader-content">
//           {/* <img src={loaderImg} alt="Loading..." /> */}
//           <AnimatedLogo logoName="Dynamic - Traffic - Solutions" />

//         </div>
//       </div>
//     )}
//     {!loading && <div className="main-content"></div>}
//   </>
//   );
// };

// export default Loader;


import React, { useState, useEffect } from 'react';
import loaderImg from '../assets/images/DTS_LOGO.png';
import AnimatedLogo from './AnimatedLogo';

const Loader = () => {
  const [loading, setLoading] = useState(true);
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    // Start the down-to-up animation after 1 second
    setTimeout(() => {
      setAnimate(false); // Trigger the down-to-up animation
    }, 2000); // Adjust the time as needed

    // Stop the loader after the animation completes
    setTimeout(() => {
      setLoading(false); // Remove the loader and show the content
    }, 3000); // Ensure this delay matches the total animation time
  }, []);

  return (
    <>
      {loading && (
        <div className={`loader-container ${!animate ? 'down-to-up' : 'up-to-down'}`}>
          <div className="loader-content-txt">
            {/* <img src={loaderImg} alt="Loading..." /> */}
            <AnimatedLogo logoName="Dynamic   Traffic   Solutions" />

          </div>
        </div>
      )}
      {/* {!loading && <div className="main-content"></div>} */}
    </>
  );
};

export default Loader;