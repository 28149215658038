import React from 'react';

function AnimatedLogo(props) {
    //console.log(props);
    const logoText = props.logoName || "Logo"; // Default to "Logo" if no prop is passed
    const letters = logoText.split(""); // Split logo text into individual letters

    return (
        <div className='logo-container'>
            {letters.map((letter, index) => (
                <span key={index} className='logo-letter' style={{ animationDelay: `${index * 0.05}s` }}>
                    {letter}
                </span>
            ))}
        </div>
    );
}

export default AnimatedLogo;
