import React from "react";
import { Link } from "react-router-dom";

const HorizontalCard = ({ imageUrl, title }) => {
  return (
    <div className="horizontal-card">
      <div className="card-image">
        <img src={imageUrl} alt={title} />
      </div>
      <div className="card-content">
        <h3>{title}</h3>
        <div className="card-footer">
          <button className="learn-more-btn"><Link 
              to="/service" style={{ color: 'white' }}>Show More</Link></button>
        </div>
      </div>
    </div>
  );
};

export default HorizontalCard;
