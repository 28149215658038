import React, { useState } from 'react';

function TextAreaInput({
    placeholder,
    maxLength,
    id,
    name,
    OnChangeTextArea,
    value
}) {
    const [errorMessage, setErrorMessage] = useState('');

    const handleValidation = (e) => {
        const textValue = e.target.value;
        OnChangeTextArea(e); // Call the passed handler to update the value

        // Perform validation
        if (!textValue) {
            setErrorMessage(`${placeholder} is required.`);
        } else if (textValue.length > maxLength) {
            setErrorMessage(`Maximum length of ${maxLength} characters exceeded.`);
        } else {
            setErrorMessage(''); // Clear error if validation passes
        }
    };

    return (
        <div>
            <textarea
                className={`field area w-input ${errorMessage ? 'input-error' : ''}`}
                placeholder={placeholder}
                maxLength={maxLength}
                id={id}
                name={name}
                data-name={placeholder}
                value={value}
                onChange={handleValidation}
            ></textarea>
            {errorMessage && <span className="error-message">{errorMessage}</span>}
        </div>
    );
}

export default TextAreaInput;
