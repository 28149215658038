import React from 'react';

const GridView = ({ data }) => {
  return (
    <div className='grid-container'>
      {data.map((item, index) => (
        <div className='grid-item' key={index}>
          <label>{item.label}</label>
        </div>
      ))}
    </div>
  );
};

export default GridView;
