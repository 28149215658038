import React, { useState } from 'react';
import { Outlet, Link, useLocation } from "react-router-dom";
import logo from '../assets/images/DTS_LOGO.png';
import '../assets/css/Header.css';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false); // State to toggle menu visibility
  const location = useLocation(); // To get the current path

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle the menu state
  };

  return (
    <>
      <nav className='header-navbar'>
        <div className='header-logo'>
        <Link 
              to="/"><img src={logo} alt="Logo" /></Link>
        </div>
        
        <div className={`hamburger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <ul className={`header-navbar-section ${menuOpen ? 'open' : ''}`}>
          <li>
            <Link 
              to="/" 
              className={location.pathname === '/' ? 'active' : ''} 
              onClick={toggleMenu}
            >
              <b>Home</b>
            </Link>
          </li>
          <li>
            <Link 
              to="/about" 
              className={location.pathname === '/about' ? 'active' : ''} 
              onClick={toggleMenu}
            >
              <b>About</b>
            </Link>
          </li>
          <li>
            <Link 
              to="/service" 
              className={location.pathname === '/service' ? 'active' : ''} 
              onClick={toggleMenu}
            >
              <b>Service</b>
            </Link>
          </li>
          <li>
            <Link 
              to="/contact" 
              className={location.pathname === '/contact' ? 'active' : ''} 
              onClick={toggleMenu}
            >
              <b>Contact</b>
            </Link>
          </li>
        </ul>
        
      </nav>
      <div className='header-tag-line'></div>
      <Outlet />
    </>
  );
}

export default Header;
