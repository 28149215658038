import React, { useState } from 'react';

function SubmitButton({ value , waitMessage , isSubmitting, isDisabled }) {
    // State to manage whether the form is submitting
    //const [isSubmitting, setIsSubmitting] = useState(false);

    // // Handler for form submission
    // const handleSubmit = (event) => {
    //     event.preventDefault();  // Prevent the default form submission
    //     setIsSubmitting(true);  // Set submitting state to true

    //     // Simulate a form submission with a timeout
    //     setTimeout(() => {
    //         setIsSubmitting(false);  // Reset submitting state
    //         alert('Form submitted!');  // Replace with your form submission logic
    //     }, 2000);  // Simulate a delay of 2 seconds
    // };

    return (
        // <form onSubmit={handleSubmit}>
            <input
                type="submit"
                className={`submit-button w-button ${isSubmitting ? 'disabled' : ''}`}
                value={isSubmitting ? waitMessage : value}
                disabled={isSubmitting}
            />
        // </form>
    );
}

export default SubmitButton;

