import React, { useState } from 'react';

function InputTextBox({ placeholder, maxLength, name, value, OnChangeText }) {
    const [errorMessage, setErrorMessage] = useState('');

    const handleValidation = (e) => {
        const inputValue = e.target.value;
        OnChangeText(e); // Call the passed handler to update the value

        // Perform validation
        if (!inputValue) {
            setErrorMessage(`${placeholder} is required.`);
        } else if (inputValue.length > maxLength) {
            setErrorMessage(`Maximum length of ${maxLength} characters exceeded.`);
        } else {
            setErrorMessage(''); // Clear error if validation passes
        }
    };

    return (
        <div>
            <input 
                className={`field w-input ${errorMessage ? 'input-error' : ''}`}
                maxLength={maxLength} 
                name={name}
                data-name={placeholder}
                placeholder={placeholder}
                type="text"
                value={value}
                onChange={handleValidation}
                required
            />
            {errorMessage && <span className="error-message">{errorMessage}</span>}
        </div>
    );
}

export default InputTextBox;
