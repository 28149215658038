import React , { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import HeaderRightImg from '../assets/images/img1.jpg'
import ourVision from '../assets/images/img2.jpg'
import HorizontalCard from '../components/HorizontalCard'
import ArrowButton from '../components/ArrowButton'
import serviceImg1 from '../assets/images/service1.png'
import serviceImg2 from '../assets/images/service2.png'
import serviceImg3 from '../assets/images/service3.png'
import serviceImg4 from '../assets/images/service4.png'
import Loader from '../components/Loader'
import { Link } from "react-router-dom";
import '../assets/css/Home.css'


function Home() {
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);
    const cardsData = [
        {
            imageUrl: serviceImg1,
            title: "Traffic Management for Government and Council: Streamlining Public Projects",
        },
        {
            imageUrl: serviceImg2,
            title: "Traffic Management for Construction: Building Safely, Building Better",
        },
        {
            imageUrl: serviceImg3,
            title: "Traffic Management for Events: Making Every Event a Success",
        },
        {
            imageUrl: serviceImg4,
            title: "Pedestrian Control: Ensuring Safe Passage for All",
        },
    ];

    return (
        <div className='home-page'>
            <Loader />
            <Header />
            <section className='header-section'>
                <div className='header-left'>
                    <h1>Dynamic Solution</h1>
                    <h4 className='h4-subtext'  style={{marginRight: '65px'}}>Dynamic Traffic Solutions is an emerging provider of innovative traffic management solutions and vehicle and pedestrian control services. With a strong commitment to safety and efficiency, we specialise in creating tailored strategies that address the specific needs of councils, businesses, construction projects, and events of all sizes. Our team of experts is dedicated to ensuring smooth traffic flow, minimising disruptions, and enhancing road safety for all users.</h4>
                    <Link to="/contact">
                        <ArrowButton btnName={'Contact Us'} />
                    </Link>
                </div>
                <div className='header-right header-right-img'>
                    <img src={HeaderRightImg} />
                </div>
            </section>
            {/* <section className='container'>
                <CardData />
            </section> */}

            <div className="card-layout">
                {cardsData.map((card, index) => (
                    <HorizontalCard style={{fontSize: '24px'}}
                        key={index}
                        imageUrl={card.imageUrl}
                        title={card.title}
                    />
                ))}
            </div>
            <section className='section-third'>
                <div className='section-third-content'>
                    <h2>Paving the Way to Safer Roads</h2>
                    <h4 className='h4-subtext'>
                        At Dynamic Traffic Solutions, our mission is to provide exceptional traffic management and vehicle and pedestrian control services that prioritise safety, efficiency, and innovation. We are committed to supporting our clients with tailored solutions that ensure seamless operations, reduce risks, and enhance road safety for all.
                    </h4>

                </div>
                <div className='homepage-about-img'>
                    <img className='maxW100' src={ourVision} />
                </div>
                <div className='section-third-content-btm'>
                    <h4 className='h4-subtext'>
                        Our vision is to be recognised as a trusted partner in the civil industry, known for our dedication to safety, excellence, and empowering communities through effective traffic management. We aim to lead the way in fostering safer, more efficient roads and event environments while setting new standards in the industry.
                    </h4>
                </div>
                <div className='about-arrow-btn'>
                    <Link to="/about">
                        <ArrowButton btnName={'About Us'} />
                    </Link>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default Home